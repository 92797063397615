import { render, staticRenderFns } from "./LireReferenceLivre.vue?vue&type=template&id=45847e77&scoped=true&"
import script from "./LireReferenceLivre.vue?vue&type=script&lang=js&"
export * from "./LireReferenceLivre.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45847e77",
  null
  
)

export default component.exports