<template>
  <div>
    <h2>Résultats</h2>
  </div>
</template>

<script>
export default {
  name: "ResultatsRecherche"
}
</script>

<style scoped>

</style>