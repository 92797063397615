<template>
  <b-container>
    <h2>Page non trouvée</h2>
  </b-container>
</template>

<script>
export default {
  name: "PageNotFound"
}
</script>

<style scoped>

</style>